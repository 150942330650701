'use client';

import { useEffect } from 'react';

import Styles from './ErrorFallback.module.scss';

interface ErrorFallbackProps {
  error: Error;
}

const ErrorBoundaryFallback = ({ error }: ErrorFallbackProps) => {
  useEffect(() => {
    console.error('Error', error);
  }, [error]);

  return (
    <div className={Styles.errorBoundary}>
      <div className={Styles.errorContainer}>
        <div className={Styles.errorContent}>
          <h2>Something went wrong!</h2>
          <div className={Styles.errorMessage}>
            <p>
              We apologize for the inconvenience. An unexpected error has
              occurred.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
